import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Grid from "../Layout/Grid"

const ContentWrapper = styled.section`
  background: ${props => props.background};
  padding-bottom: 50px;

  @media screen and (min-width: 800px) {
    background: unset;
    padding-bottom: 74px;
  }

  .background {
    display: none;
    @media screen and (min-width: 800px) {
      display: grid;
      grid-template-columns: 1fr 1fr 3fr;
      background: ${props => props.background};
      position: sticky;
      height: 90vh;
      top: 60px;
    }
    z-index: -1;
  }

  .navigation {
    display: none;
    margin-top: -85vh;

    @media screen and (min-width: 800px) {
      display: block;
      position: sticky;
      top: 90px;
      width: 25%;
    }

    .sidebar {
      list-style: none;

      #step01,
      #step02,
      #step03 {
        color: var(--navy);

        .link {
          color: var(--navy);
        }
      }

      @media screen and (min-width: 800px) {
        #step01 {
          color: ${props =>
            props.navigation === 1 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};

          .link {
            color: ${props =>
              props.navigation === 1 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};
          }
        }

        #step02 {
          color: ${props =>
            props.navigation === 2 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};

          .link {
            color: ${props =>
              props.navigation === 2 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};
          }
        }

        #step03 {
          color: ${props =>
            props.navigation === 3 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};

          .link {
            color: ${props =>
              props.navigation === 3 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};
          }
        }
      }

      .nav-item {
        .nav-title {
          font-family: var(--subheading);
          font-size: 30px;
          line-height: 31px;
          text-transform: uppercase;
        }
      }
    }
  }

  .content {
    display: grid;

    @media screen and (min-width: 800px) {
      grid-template-columns: 1fr 1fr 2fr;
      margin-top: -22vh;
      padding: 0rem 1rem;
    }

    @media screen and (min-width: 800px) {
      padding: 0 7rem 0 0;
    }

    .content-title {
      color: var(--navy);
      font-family: var(--heading);
      font-size: 72px;
      line-height: 72px;
      text-transform: lowercase;
      padding: 4rem 0;

      @media screen and (min-width: 800px) {
        font-size: 100px;
        line-height: 100px;
        padding: 0;
      }
    }

    .content-desc {
      color: var(--navy);
      font-family: var(--heading);
      font-size: 18px;
      line-height: 18px;

      @media screen and (min-width: 800px) {
        font-size: 40px;
        line-height: 40px;
      }
    }

    .divider {
      color: var(--navy);
      margin: 3rem 0;
    }

    .link-wrapper {
      display: flex;
      flex-direction: column;

      .content-link {
        color: var(--navy);
        font-family: var(--heading);
        font-size: 18px;
        line-height: 24px;

        @media screen and (min-width: 800px) {
          font-size: 24px;
          line-height: 34px;
        }

        margin-bottom: 14px;
      }
    }
  }
`

const CO00Content = () => {
  const [background, setBackground] = useState("var(--light-bg-blue)")
  const [navigation, setNavigation] = useState(1)

  return (
    <ContentWrapper navigation={navigation} background={background}>
      <Grid>
        <div className="background"></div>
        <div className="navigation">
          <ul className="sidebar">
            <li id="step01" className="nav-item">
              <h2 className="nav-title">New Project</h2>
            </li>
            <li id="step02" className="nav-item">
              <h2 className="nav-title">Joining Our Team</h2>
            </li>
            <li id="step03" className="nav-item">
              <h2 className="nav-title">General Inquiry</h2>
            </li>
          </ul>
        </div>
        <div className="content">
          <div className="blank"></div>
          <div className="blank"></div>
          <div className="content-wrapper">
            <h1 className="content-title">Thank You</h1>
            <h2 className="content-desc">We'll get back to you shortly.</h2>
            <hr className="divider" />
            <div className="link-wrapper">
              <Link className="content-link" to="/work">
                View our projects
              </Link>
              <Link className="content-link" to="/about">
                Learn more about us
              </Link>
            </div>
          </div>
        </div>
      </Grid>
    </ContentWrapper>
  )
}

export default CO00Content
