import React from "react"
import Layout from "../components/Layout/Layout"
import Head from "../components/Layout/Head"
import CO05Success from "../components/Contact/CO05Success"

const SuccessPage = () => {
  return (
    <Layout>
      <Head title="Thank You" />
      <CO05Success></CO05Success>
    </Layout>
  )
}

export default SuccessPage
